import { i18n } from 'plugins'
import { urlPath } from 'utils'

export const PRODUCTS = [
  {
    title: i18n.t('material_master.title') as string,
    children: [
      {
        title: i18n.t('material_master.product.title') as string,
        path: urlPath.MASTER_MATERIAL_ITEMS.path
      },
      {
        title: i18n.t('material_master.unit.title') as string,
        path: urlPath.MASTER_MATERIAL_UNITS.path
      }
    ]
  }
]
