













































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, showError, toCamelCase } from 'utils'
import { Grower } from 'typings'

const GrowerDialog = defineComponent({
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }) {
    const { $toast } = root
    const isChoose = ref(false)
    const loading = ref(false)
    const growers = ref<Array<Grower>>([])
    const selectedGrower = ref<number | null>(null)

    const getGrowers = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.GROWER)
        // need to convert result from snake_case to camelCase
        growers.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    onMounted(getGrowers)

    return {
      isChoose,
      growers,
      selectedGrower
    }
  }
})

export default GrowerDialog
