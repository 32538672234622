




















































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api, framebus } from 'plugins'
import { endpoints, toCamelCase, toSnakeCase, showError, frameBusEvent, constants } from 'utils'
import {
  Variety,
  Item,
  VarietiesByItem,
  Body,
  ObjectWrapper,
  BottomSheetButton,
  SizeGroup,
  QualityGroup,
  NurseryCompany
} from 'typings'
import { VarietyDialog, ItemDialog, MasterItemGroupComponent } from 'components'

interface SetUp {
  varietiesByItem: Ref<VarietiesByItem[]>
  searchedVarieties: Ref<VarietiesByItem[]>
  showVarietyDialog: Ref<boolean>
  readonly: Ref<boolean>
  isAddVariety: Ref<boolean>
  varietyProp: Ref<ObjectWrapper>
  openVarietyDialog: (param: Variety | number) => void
  saveVarietiesOrderInItem: (body: Body) => void
  showItemDialog: Ref<boolean>
  isAddItem: Ref<boolean>
  itemProp: Ref<ObjectWrapper>
  openItemDialog: (item: Item | null) => void
  saveItemOrder: (body: Body) => void
  getVarietiesGroupedByItem: () => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
  sizeGroups: Ref<SizeGroup[]>
  qualityGroups: Ref<QualityGroup[]>
  nurseryCompanies: Ref<NurseryCompany[]>
}

const Varieties = defineComponent({
  components: {
    VarietyDialog,
    ItemDialog,
    MasterItemGroupComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const varietiesByItem: Ref<VarietiesByItem[]> = ref([])
    const searchedVarieties: Ref<VarietiesByItem[]> = ref([])
    const isSorting = ref(false)
    const readonly = ref(false)
    const { $store, $toast } = root
    const isAddVariety = ref(true)
    const varietyProp: Ref<ObjectWrapper> = ref({})
    const showVarietyDialog = ref(false)

    const showItemDialog = ref(false)
    const isAddItem = ref(true)
    const itemProp = ref({})
    const loading = ref(false)

    // list button to bottom sheet panel
    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.item.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add-group',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.variety.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('master.item.sort_items') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort-group',
        disabled: false
      },
      {
        id: 4,
        text: root.$t('master.variety.sort_varieties_in_1_item') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 5,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const sizeGroups: Ref<SizeGroup[]> = ref([])
    const qualityGroups: Ref<QualityGroup[]> = ref([])
    const nurseryCompanies: Ref<NurseryCompany[]> = ref([])

    const getVarietiesGroupedByItem = async (): Promise<void> => {
      loading.value = true
      try {
        // load data by role
        const userInfo = await $store.getters.getUserInfo
        let url
        if (userInfo.memberRole !== constants.memberRole.ROLE_CHILD_GROWER) {
          url = `${endpoints.VARIETIES}group_by_item`
        } else {
          url = `${endpoints.GROWER_VARIETIES}group_by_item`
        }
        const response = await api.get(url)
        varietiesByItem.value = toCamelCase(
          response.data.map((group: VarietiesByItem) => {
            return { ...group, active: false }
          })
        )
        searchedVarieties.value = [...varietiesByItem.value]
        // eslint-disable-next-line no-restricted-globals
        // parent.postMessage(toCamelCase(response.data), '*')
        // $store.commit('setAllVarietyByItem', toCamelCase(response.data))
        // send data item variety to iframe
        framebus.emit(frameBusEvent.ITEM_VARIETY, {
          contents: toCamelCase(response.data)
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveVarietiesOrderInItem = async (body: Body | null): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.VARIETIES}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getVarietiesGroupedByItem()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    /*
     * param: could be object type or number
     * object type (for edit variety)
     * number type (for create variety) is opening Item's id, will be set to Item field
     * */
    const openVarietyDialog = (param: Variety | number): void => {
      if (typeof param === 'object') {
        varietyProp.value = JSON.parse(JSON.stringify(param))
        isAddVariety.value = false
      } else {
        const currentOpeningItem = varietiesByItem.value.find((item) => item.id === param)
        if (currentOpeningItem) {
          varietyProp.value = {
            id: 0,
            name: '',
            nameEng: null,
            orderNum: null,
            searchStr: null,
            shortName: null,
            yomi: null,
            item: currentOpeningItem.id,
            // inherit sizeGroup from opening item
            sizeGroup: currentOpeningItem.sizeGroup?.id,
            qualityGroup: currentOpeningItem.qualityGroup?.id
          }
        } else {
          varietyProp.value = {
            id: 0,
            name: '',
            nameEng: null,
            orderNum: null,
            searchStr: null,
            shortName: null,
            yomi: null,
            // set default item and default size group
            item: 1,
            sizeGroup: 1,
            qualityGroup: 1
          }
        }
        isAddVariety.value = true
      }
      showVarietyDialog.value = true
    }

    const saveItemOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.ITEMS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getVarietiesGroupedByItem()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openItemDialog = (item: Item | null): void => {
      if (item) {
        itemProp.value = JSON.parse(JSON.stringify(item))
        isAddItem.value = false
      } else {
        // (create new item) set item's init size_group id to 1
        itemProp.value = {
          name: '',
          shortName: null,
          yomi: null,
          nameEng: null,
          defaultUnit: {},
          units: [],
          sizeGroup: 1,
          qualityGroup: 1
        }
        isAddItem.value = true
      }
      showItemDialog.value = true
    }

    const getSizeGroups = async (): Promise<void> => {
      try {
        const response = await api.get(endpoints.SIZE_GROUPS)
        sizeGroups.value = toCamelCase(response.data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getQualityGroups = async (): Promise<void> => {
      try {
        const response = await api.get(endpoints.QUALITY_GROUPS)
        qualityGroups.value = toCamelCase(response.data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getNurseryCompanies = async (): Promise<void> => {
      try {
        const response = await api.get(endpoints.NURSERY_COMPANY)
        nurseryCompanies.value = toCamelCase(response.data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const setReadonlyFields = async () => {
      const userInfo = await $store.getters.getUserInfo
      readonly.value = userInfo.memberRole === constants.memberRole.ROLE_CHILD_GROWER
    }

    onMounted(() => {
      Promise.all([
        getVarietiesGroupedByItem(),
        getSizeGroups(),
        getQualityGroups(),
        getNurseryCompanies(),
        setReadonlyFields()
      ])
    })

    return {
      openVarietyDialog,
      varietyProp,
      searchedVarieties,
      showVarietyDialog,
      isAddVariety,
      isSorting,
      getVarietiesGroupedByItem,
      varietiesByItem,
      loading,
      saveVarietiesOrderInItem,
      openItemDialog,
      showItemDialog,
      isAddItem,
      itemProp,
      saveItemOrder,
      bottomSheetListButton,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      readonly
    }
  }
})
export default Varieties
