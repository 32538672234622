















































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, showError, toSnakeCase, constants } from 'utils'
import {
  Quality,
  QualityGroup,
  Body,
  BottomSheetButton,
  QualitiesByQualityGroup,
  ObjectWrapper
} from 'typings'
import { QualityDialog, QualityGroupDialog, MasterItemGroupComponent } from 'components'

interface SetUp {
  qualitiesByQualityGroup: Ref<QualitiesByQualityGroup[]>
  searchedQualities: Ref<QualitiesByQualityGroup[]>
  showQualityDialog: Ref<boolean>
  isAddQuality: Ref<boolean>
  qualityProp: Ref<unknown>
  openQualityDialog: (param: Quality | number) => void
  saveQualityOrderInQualityGroup: (body: Body) => void
  showQualityGroupDialog: Ref<boolean>
  isAddQualityGroup: Ref<boolean>
  qualityGroupProp: Ref<ObjectWrapper>
  openQualityGroupDialog: (qualityGroup: QualityGroup | null) => void
  saveQualityGroupOrder: (body: Body) => void
  getQualitiesGroupedByQualityGroup: () => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  readonly: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
}

const Qualities = defineComponent({
  components: {
    QualityDialog,
    QualityGroupDialog,
    MasterItemGroupComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const qualitiesByQualityGroup: Ref<QualitiesByQualityGroup[]> = ref([])
    const searchedQualities: Ref<QualitiesByQualityGroup[]> = ref([])
    const isSorting = ref(false)

    const qualityProp = ref({})
    const showQualityDialog = ref(false)
    const isAddQuality = ref(true)

    const qualityGroupProp: Ref<ObjectWrapper> = ref({})
    const showQualityGroupDialog = ref(false)
    const isAddQualityGroup = ref(true)

    const { $store, $toast } = root

    const loading = ref(false)
    const readonly = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.quality_group.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add-group',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.quality.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('master.quality_group.sort_quality_groups') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort-group',
        disabled: false
      },
      {
        id: 4,
        text: root.$t('master.quality.sort_qualities_in_1_group') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 5,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getQualitiesGroupedByQualityGroup = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(`${endpoints.QUALITIES}grouped_by_quality_group`)
        // need to convert result from snake_case to camelCase
        qualitiesByQualityGroup.value = toCamelCase(
          data.map((group: QualitiesByQualityGroup) => {
            return { ...group, active: false }
          })
        )
        searchedQualities.value = [...qualitiesByQualityGroup.value]
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveQualityOrderInQualityGroup = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.QUALITIES}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getQualitiesGroupedByQualityGroup()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const saveQualityGroupOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.QUALITY_GROUPS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getQualitiesGroupedByQualityGroup()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    /*
     * param: could be object type or number
     * object type (for edit quality)
     * number type (for create quality) is QualityGroup's id, will be set to Quality Group field
     * */
    const openQualityDialog = (param: Quality | number): void => {
      if (typeof param === 'object') {
        qualityProp.value = JSON.parse(JSON.stringify(param))
        isAddQuality.value = false
      } else {
        qualityProp.value = {
          id: 0,
          name: '',
          nameEng: null,
          orderNum: null,
          searchStr: null,
          shortName: null,
          yomi: null,
          // id of Quality group to set
          // 1 is the id of Default group
          qualityGroup: param || 1
        }
        isAddQuality.value = true
      }
      showQualityDialog.value = true
    }

    const openQualityGroupDialog = (qualityGroup: QualityGroup | null): void => {
      if (qualityGroup) {
        qualityGroupProp.value = JSON.parse(JSON.stringify(qualityGroup))
        isAddQualityGroup.value = false
      } else {
        qualityGroupProp.value = {
          id: 0,
          isDefault: false,
          name: '',
          nameEng: null,
          orderNum: null,
          searchStr: null,
          shortName: null,
          yomi: null
        }
        isAddQualityGroup.value = true
      }
      showQualityGroupDialog.value = true
    }

    const setReadonlyFields = async () => {
      const userInfo = await $store.getters.getUserInfo
      readonly.value = userInfo.memberRole === constants.memberRole.ROLE_CHILD_GROWER
    }

    onMounted(() => {
      getQualitiesGroupedByQualityGroup()
      setReadonlyFields()
    })

    return {
      qualitiesByQualityGroup,
      qualityProp,
      showQualityDialog,
      isAddQuality,
      qualityGroupProp,
      showQualityGroupDialog,
      isAddQualityGroup,
      isSorting,
      loading,
      getQualitiesGroupedByQualityGroup,
      openQualityDialog,
      saveQualityOrderInQualityGroup,
      saveQualityGroupOrder,
      openQualityGroupDialog,
      searchedQualities,
      bottomSheetListButton,
      readonly
    }
  }
})

export default Qualities
