

















import { defineComponent, reactive } from '@vue/composition-api'
import { SectionHeader } from 'components'

import { PRODUCTS } from './products'

interface SubItem {
  title: string
  path: string
}
interface Item {
  title: string
  children: Array<SubItem>
}
interface SetUp {
  products: Array<Item>
  goToPage: (property: string) => void
}

const Products = defineComponent({
  components: {
    SectionHeader
  },
  props: {},
  setup(props, { root }): SetUp {
    const products: Array<Item> = reactive(PRODUCTS)

    const goToPage = (url: string) => {
      if (url !== root.$route.path) {
        root.$router.push({
          path: url,
          query: { called: 'true' }
        })
      }
    }

    return {
      products,
      goToPage
    }
  }
})

export default Products
