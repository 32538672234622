

















































import { defineComponent, ref } from '@vue/composition-api'

const SelectPackingDialog = defineComponent({
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    packingImports: {
      type: Array,
      required: true
    }
  },
  setup() {
    const selectedPackingImport = ref(0)
    return {
      selectedPackingImport
    }
  }
})

export default SelectPackingDialog
