

























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, showError, toSnakeCase } from 'utils'
import { Unit, Body, BottomSheetButton } from 'typings'
import { MaterialUnitDialog, MasterItemComponent } from 'components'

interface SetUp {
  units: Ref<Unit[]>
  showUnitDialog: Ref<boolean>
  isAdd: Ref<boolean>
  unitProp: Ref<unknown>
  openUnitDialog: (unit: Unit) => void
  getUnits: () => void
  confirmReset: (param: string) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  saveSortOrder: (body: Body) => void
  bottomSheetListButton: Array<BottomSheetButton>
}

const Units = defineComponent({
  components: {
    MaterialUnitDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const units: Ref<Unit[]> = ref([])
    const isSorting = ref(false)
    const unitProp = ref({})
    const showUnitDialog = ref(false)
    const isAdd = ref(true)
    const { $toast } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('material_master.unit.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('material_master.unit.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getUnits = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.MATERIAL_UNITS)
        // need to convert result from snake_case to camelCase
        units.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.MATERIAL_UNITS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getUnits()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openUnitDialog = (unit: Unit | null): void => {
      if (unit) {
        unitProp.value = JSON.parse(JSON.stringify(unit))
        isAdd.value = false
      } else {
        unitProp.value = {}
        isAdd.value = true
      }
      showUnitDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.unit.unit_list_is_reset'))
      await getUnits()
    }

    onMounted(getUnits)

    return {
      units,
      unitProp,
      showUnitDialog,
      isAdd,
      isSorting,
      loading,
      openUnitDialog,
      getUnits,
      confirmReset,
      saveSortOrder,
      bottomSheetListButton
    }
  }
})

export default Units
