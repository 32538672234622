
























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'

import { api, framebus } from 'plugins'
import { endpoints, toCamelCase, toSnakeCase, showError, frameBusEvent } from 'utils'
import { Item, Body, VarietiesByItem } from 'typings'
import { ItemDialog, MasterItemComponent } from 'components'

interface SetUp {
  items: Ref<Array<Item>>
  showItemDialog: Ref<boolean>
  isAdd: Ref<boolean>
  itemProp: Ref<unknown>
  openItemDialog: (item: Item) => void
  getItems: () => void
  saveSortOrder: (body: Body) => void
  searchInfo: Ref<string>
  confirmReset: (param: string) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
}

const Items = defineComponent({
  components: {
    ItemDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const items: Ref<Array<Item>> = ref([])
    const searchInfo = ref('')
    const isSorting = ref(false)
    const itemProp = ref({})
    const showItemDialog = ref(false)
    const isAdd = ref(true)
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const { $toast, $store } = root

    const loading = ref(false)

    const getItems = async (): Promise<void> => {
      loading.value = true
      searchInfo.value = ''
      try {
        const response = await api.get(endpoints.ITEMS)
        // need to convert result from snake_case to camelCase
        items.value = toCamelCase(response.data)
        varietiesByItem.value = $store.state.common.allVarietyByItem
        const newData = $store.state.common.newDataMaster
        varietiesByItem.value.push({ ...newData, varieties: [] })
        $store.commit('setAllVarietyByItem', varietiesByItem.value)
        // eslint-disable-next-line no-restricted-globals
        // parent.postMessage(varietiesByItem.value, '*')
        framebus.emit(frameBusEvent.ITEM_VARIETY, {
          contents: varietiesByItem.value
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.ITEMS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getItems()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openItemDialog = (item: Item | null): void => {
      if (item) {
        itemProp.value = JSON.parse(JSON.stringify(item))
        isAdd.value = false
      } else {
        // default item prop
        itemProp.value = {}
        isAdd.value = true
      }
      showItemDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.item.item_list_is_reset'))
      await getItems()
    }

    onMounted(getItems)

    return {
      items,
      itemProp,
      searchInfo,
      showItemDialog,
      isAdd,
      isSorting,
      loading,
      openItemDialog,
      getItems,
      confirmReset,
      saveSortOrder
    }
  }
})

export default Items
