












































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  Ref,
  watch
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { snakeCase } from 'lodash'
import Vue from 'vue'

import {
  defaultObject,
  endpoints,
  showError,
  scrollTop,
  toCamelCase,
  toSnakeCase,
  urlPath,
  getSortStates,
  scrollBottom,
  matchData,
  frameBusEvent,
  backPage,
  checkIsOnPC,
  mappingKeyAndField
} from 'utils'
import { api, framebus, moment } from 'plugins'
import {
  IPackingResult,
  IBreakdown,
  Variety,
  CommonProperty,
  Color,
  Size,
  IDelivery,
  IAssign,
  PackingResult,
  Quality,
  Item,
  OrderDetail,
  OrderType,
  IState,
  SizeGroup,
  QualityGroup,
  VarietiesByItem,
  SizesBySizeGroup,
  QualitiesByQualityGroup,
  NurseryCompany,
  CustomerGroup,
  MappingKeyAndField
} from 'typings'

import {
  ChooseColorSingle,
  ChooseNumber,
  ChooseProperty,
  ConfirmDelete,
  ConfirmDialog,
  SelectInputRow,
  CreateCommonForm,
  BreakdownContainer,
  DeliveryContainer,
  SingleDetailItem
} from 'components'

interface PackingOrderDetail extends OrderDetail {
  price: number
  orderType: OrderType
}

interface SetUp {
  id: Ref<number | null>
  requiredRows: Array<string>
  packingResultDetail: IPackingResult
  modal: Ref<boolean>
  showConfirmOverBoxesDialog: Ref<boolean>
  numberDialog: Ref<boolean>
  colorDialog: Ref<boolean>
  colors: Ref<Array<Color>>
  dialog: Ref<boolean>
  showDelete: Ref<boolean>
  disabledRows: Array<string>
  confirmDelete: (action: string) => Promise<void>
  setPropertyName: Ref<string>
  chooseProperty: (property: string) => void
  choosePropertyItems: Ref<Array<CommonProperty>>
  updatePackingResultDetail: (action: string, item: CommonProperty | number) => void
  selectProperty: (action: string) => void
  checkBeforeCreateOrUpdate: (enableGoBack: boolean) => Promise<PackingResult | null>
  createOrUpdateDetail: (enableGoBack: boolean) => Promise<PackingResult | null>
  createNewDetail: () => Promise<void>
  duplicateDetail: () => void
  getPropertyDialogTitle: () => string
  getNumberDialogTitle: () => string
  uploadLoading: Ref<boolean>
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  addBreakdown: () => void
  updateBreakdown: (data: IBreakdown, index: number) => void
  removeBreakdown: (index: number) => void
  addDelivery: () => void
  updateDelivery: (data: IDelivery, index: number) => void
  removeDelivery: (index: number) => void
  items: Ref<Array<CommonProperty>>
  varieties: Ref<Array<CommonProperty>>
  sizes: Ref<Array<CommonProperty>>
  qualities: Ref<Array<CommonProperty>>
  customers: Ref<Array<CommonProperty>>
  orderTypes: Ref<Array<CommonProperty>>
  customerGroups: Ref<Array<CustomerGroup>>
  close: () => void
  thisPage: Ref<string>
  saveCurrentStates: () => void
  isShowAction: Ref<boolean>
  totalBoxesDelivery: Ref<number>
  componentKeyDelivery: Ref<number>
  componentKeyBreakdown: Ref<number>
  sizeGroups: Ref<SizeGroup[]>
  qualityGroups: Ref<QualityGroup[]>
  nurseryCompanies: Ref<NurseryCompany[]>
  getNewData: (mode: string) => void
  itemSelectVariety: Ref<number>
  groupDataMaster: Ref<Array<any>>
  getData: () => void
  isUpdateCommon: boolean
  isOnPC: boolean
  deletePackingDetail: () => void
  addEventTyping: () => void
  removeEventTyping: () => void
}

const PackingResultDetail = defineComponent({
  components: {
    ChooseNumber,
    ChooseProperty,
    ChooseColorSingle,
    SelectInputRow,
    ConfirmDelete,
    ConfirmDialog,
    CreateCommonForm,
    BreakdownContainer,
    DeliveryContainer,
    SingleDetailItem
  },
  props: {},
  setup(props, { root, refs }): SetUp {
    const thisPage = ref('packing')
    const isShowAction = ref(false)
    const { $route, $toast, $router, $store } = root
    const {
      defaultPackedDate,
      defaultAuctionDate,
      defaultItem,
      defaultVariety,
      defaultSize,
      defaultQuality,
      orderDetailIds
    } = $route.params
    let { detailId, isDuplicated } = $route.params
    const isUpdateCommon = $route.params.packingImportId === '0'
    const id = ref(Number(detailId) || null)
    const componentKeyDelivery = ref(0)
    const componentKeyBreakdown = ref(0)
    const onSaving = ref(false)
    const isOnPC = checkIsOnPC()
    const LIST_PROPERTY = [
      'packedDate',
      'item',
      'variety',
      'color',
      'quality',
      'size',
      'quantity',
      'unit',
      'boxes',
      'boxType',
      'stems',
      'desiredPrice'
    ]

    // const listStates = [...defaultObject.listPackingStates]
    const initDetail: IPackingResult = { ...defaultObject.initPackingResultDetail }
    const initBreakdown: IBreakdown = { ...defaultObject.initBreakdown }
    const initDelivery: IDelivery = { ...defaultObject.initDelivery }
    const packingResultDetail: IPackingResult = reactive({ ...initDetail })
    const disabledRows = [
      'packedDate',
      'item',
      'variety',
      'color',
      'quality',
      'size',
      'quantity',
      'unit',
      'boxes',
      'boxType',
      'totalStems'
    ]
    const requiredRows = Object.keys(packingResultDetail).filter((key: string) => {
      const unrequiredRows = ['color', 'desiredPrice']
      return !unrequiredRows.includes(key)
    })

    const modal = ref(false)
    const numberDialog = ref(false)
    const colorDialog = ref(false)
    const dialog = ref(false)
    const showDelete = ref(false)
    const showConfirmOverBoxesDialog = ref(false)
    const setPropertyName = ref('')

    const items = ref<Array<any>>([])
    const varieties = ref<Array<any>>([])
    const sizes = ref<Array<any>>([])
    const colors = ref<Array<any>>([])
    const boxTypes = ref<Array<any>>([])
    const units = ref<Array<any>>([])
    const qualities = ref<Array<any>>([])
    const customers = ref<Array<any>>([])
    const orderTypes = ref<Array<any>>([])
    const orderDetailsData = ref([] as Array<PackingOrderDetail>)
    const listStateSetting = ref<Array<string>>([])
    const listStates = ref<Array<string>>([])
    const listStateStore = ref<IState>($store.state.listStates)
    const groupDataMaster = ref<Array<any>>([])

    const choosePropertyItems = ref<Array<any>>([])

    // for API POST/PUT
    const uploadLoading = ref(false)
    // for API GET
    const loading = ref(false)
    // for API DELETE
    const deleteLoading = ref(false)
    const itemSelectVariety = ref(1)

    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])
    const customerGroups = ref<Array<CustomerGroup>>([])

    const nextProperty = computed(() => {
      const key = setPropertyName.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) + 1
      return listStates.value[indexKey]
    })

    const prevProperty = computed(() => {
      const key = setPropertyName.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) - 1
      return listStates.value[indexKey]
    })

    const varietyList = computed(() => {
      const itemId = packingResultDetail.item?.id

      if (!itemId) return []

      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    const sizeList = computed(() => {
      const itemSizeGroupId = packingResultDetail.item?.sizeGroup?.id
      const varietySizeGroupId = packingResultDetail.variety?.sizeGroup?.id

      if (varietySizeGroupId) {
        return sizes.value.filter((size: Size) => size.sizeGroup.id === varietySizeGroupId)
      }
      if (itemSizeGroupId) {
        return sizes.value.filter((size: Size) => size.sizeGroup.id === itemSizeGroupId)
      }
      return []
    })

    const qualityList = computed(() => {
      const itemQualityGroupId = packingResultDetail.item?.qualityGroup?.id
      const varietyQualityGroupId = packingResultDetail.variety?.qualityGroup?.id

      if (varietyQualityGroupId) {
        return qualities.value.filter(
          (quality: Quality) => quality.qualityGroup.id === varietyQualityGroupId
        )
      }
      if (itemQualityGroupId) {
        return qualities.value.filter(
          (quality: Quality) => quality.qualityGroup.id === itemQualityGroupId
        )
      }
      return []
    })

    const chooseProperty = (property: string): void => {
      setPropertyName.value = property
      modal.value = false
      dialog.value = false
      colorDialog.value = false
      numberDialog.value = false

      switch (property) {
        case 'packedDate':
          modal.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          groupDataMaster.value = varietiesByItem.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizeList.value
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'color':
          colorDialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = units.value
          dialog.value = true
          break
        case 'boxType':
          choosePropertyItems.value = boxTypes.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualityList.value
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        case 'totalStems':
          numberDialog.value = true
          break
        case 'desiredPrice':
          numberDialog.value = true
          break
        default:
          break
      }
    }

    const selectProperty = (action: string): void => {
      packingResultDetail.stems = packingResultDetail.stems === 0 ? null : packingResultDetail.stems
      packingResultDetail.boxes = packingResultDetail.boxes === 0 ? null : packingResultDetail.boxes
      packingResultDetail.quantity =
        packingResultDetail.quantity === 0 ? null : packingResultDetail.quantity
      packingResultDetail.price = packingResultDetail.price === 0 ? null : packingResultDetail.price
      packingResultDetail.amount =
        packingResultDetail.amount === 0 ? null : packingResultDetail.amount
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      }
    }

    const updatePackingResultDetail = (
      action: string,
      item: CommonProperty | Item | number
    ): void => {
      const key = setPropertyName.value as keyof IPackingResult
      const oldValue = packingResultDetail[key]
      packingResultDetail[key] = item
      if (key === 'item') {
        packingResultDetail.variety = null
        if (
          oldValue?.sizeGroup?.id !== packingResultDetail[key]?.sizeGroup?.id ||
          packingResultDetail[key]?.sizeGroup?.id !== packingResultDetail.size?.sizeGroup?.id
        ) {
          packingResultDetail.size = null
        }
        if (
          oldValue?.qualityGroup?.id !== packingResultDetail[key]?.qualityGroup?.id ||
          packingResultDetail[key]?.qualityGroup?.id !==
            packingResultDetail.quality?.qualityGroup?.id
        ) {
          packingResultDetail.quality = null
        }
        packingResultDetail.unit = (item as Item).defaultUnit
        itemSelectVariety.value = (item as Item).id
      }

      if (key === 'variety') {
        const compareValue = oldValue ?? packingResultDetail.item
        if (
          compareValue?.sizeGroup?.id !== packingResultDetail[key]?.sizeGroup?.id ||
          packingResultDetail[key]?.sizeGroup?.id !== packingResultDetail.size?.sizeGroup?.id
        ) {
          packingResultDetail.size = null
        }
        if (
          compareValue?.qualityGroup?.id !== packingResultDetail[key]?.qualityGroup?.id ||
          packingResultDetail[key]?.qualityGroup?.id !==
            packingResultDetail.quality?.qualityGroup?.id
        ) {
          packingResultDetail.quality = null
        }
      }

      // update totalStems if user change quantity or boxes number
      if (key === 'quantity' || key === 'boxes') {
        packingResultDetail.totalStems =
          (Number(packingResultDetail.quantity) || 0) * (Number(packingResultDetail.boxes) || 0)
      }

      selectProperty(action)
    }

    let removedDeliveryIds: Array<number> = []

    const addDelivery = () => {
      if (!componentKeyDelivery.value) {
        // avoid open when edit
        componentKeyDelivery.value += 1
      } else {
        componentKeyDelivery.value += packingResultDetail.deliveries.length
      }
      const defaultDelivery = reactive({ ...initDelivery })
      const len = packingResultDetail.deliveries.length
      if (len) {
        // copy auction date from the last delivery
        defaultDelivery.auctionDate = packingResultDetail.deliveries[len - 1].auctionDate
        defaultDelivery.orderType = packingResultDetail.deliveries[len - 1].orderType
      } else if (moment(defaultAuctionDate as string, 'YYYY-MM-DD', true).isValid()) {
        // get auction date from packing result list screen
        defaultDelivery.auctionDate = defaultAuctionDate
      } else {
        // if set as today
        defaultDelivery.auctionDate = moment().format('YYYY-MM-DD')
      }
      packingResultDetail.deliveries = [].concat(packingResultDetail.deliveries, defaultDelivery)

      // scroll to the newest container
      window.setTimeout(() => {
        const containers = refs.deliveryContainers as Array<Vue>
        const lastIndex = containers.length - 1
        const lastContainer = containers[lastIndex]
        if (lastContainer.$el) {
          const top = (lastContainer.$el as HTMLElement).offsetTop
          window.scrollTo({ top, behavior: 'smooth' })
        }
      }, 300)
    }

    const updateDelivery = (data: IDelivery, index: number) => {
      if (index > -1) {
        packingResultDetail.deliveries[index] = data
      }
    }

    const removeDelivery = (index: number) => {
      if (index > -1) {
        const removedDelivery = packingResultDetail.deliveries[index]
        if (removedDelivery.id) {
          removedDeliveryIds.push(removedDelivery.id)
        }
        packingResultDetail.deliveries.splice(index, 1)
      }
    }

    const getDetailInfo = async (): Promise<void> => {
      if (detailId && detailId !== '0') {
        const { data } = await api.get(`${endpoints.PACKING_RESULTS}${detailId}`)
        const detail = toCamelCase(data)

        // convert breakdowns to object
        detail.breakdowns = detail.breakdowns.map((breakdown: IBreakdown) => {
          return {
            item: items.value.filter((item: CommonProperty) => {
              return item.id === breakdown.itemId
            })[0],
            variety: varieties.value.filter((variety: CommonProperty) => {
              return variety.id === breakdown.varietyId
            })[0],
            size: sizes.value.filter((size: CommonProperty) => {
              return size.id === breakdown.sizeId
            })[0],
            quality: qualities.value.filter((quality: CommonProperty) => {
              return quality.id === breakdown.qualityId
            })[0],
            color: colors.value.filter((color: CommonProperty) => {
              return color.id === breakdown.colorId
            })[0],
            quantity: breakdown.quantity
          }
        })
        detail.deliveries = detail.assignments

        Object.keys(detail).forEach((key) => {
          packingResultDetail[key] = detail[key]
        })
        itemSelectVariety.value = packingResultDetail.item.id
      } else {
        if (orderDetailIds) {
          const { data } = await api.get(`${endpoints.ORDER_DETAILS}?detail_ids=${orderDetailIds}`)
          orderDetailsData.value = toCamelCase(data)

          if (orderDetailsData.value.length === 1) {
            Object.keys(packingResultDetail).forEach((key: string) => {
              if (key in orderDetailsData.value[0]) {
                packingResultDetail[key] =
                  orderDetailsData.value[0][key as keyof PackingOrderDetail]
              }
            })
            // eslint-disable-next-line prefer-destructuring
            packingResultDetail.color = orderDetailsData.value[0].colors[0] // get the first one
            packingResultDetail.totalStems =
              packingResultDetail.quantity * packingResultDetail.boxes
            packingResultDetail.desiredPrice = orderDetailsData.value[0].price
          }

          orderDetailsData.value.forEach((orderDetailData: PackingOrderDetail) => {
            // add a new delivery with order detail info
            const newDelivery: IDelivery = {
              customer: orderDetailData.order.customer,
              buyerInfo: orderDetailData.buyerInfo,
              bundleNo: null,
              orderType: orderDetailData.orderType,
              boxes: orderDetailData.boxes,
              stems: orderDetailData.stems,
              price: orderDetailData.price,
              orderDetailId: orderDetailData.id,
              auctionDate: orderDetailData.order.auctionDate
            }
            packingResultDetail.deliveries = [].concat(packingResultDetail.deliveries, newDelivery)
          })
        }

        // set default packedDate when create new packing result
        packingResultDetail.packedDate = defaultPackedDate

        if (defaultItem) {
          const ArrDefaultItem = items.value.filter((item: CommonProperty) => {
            return item.id === Number(defaultItem)
          })
          if (ArrDefaultItem.length) {
            // eslint-disable-next-line prefer-destructuring
            packingResultDetail.item = ArrDefaultItem[0]
          }
        }

        if (defaultVariety) {
          const ArrDefaultVariety = varieties.value.filter((variety: Variety) => {
            return variety.id === Number(defaultVariety)
          })
          if (ArrDefaultVariety.length) {
            // eslint-disable-next-line prefer-destructuring
            packingResultDetail.variety = ArrDefaultVariety[0]
          }
        }

        if (defaultSize) {
          const ArrDefaultSize = sizes.value.filter((size: Size) => {
            return size.id === Number(defaultSize)
          })
          if (ArrDefaultSize.length) {
            // eslint-disable-next-line prefer-destructuring
            packingResultDetail.size = ArrDefaultSize[0]
          }
        }

        if (defaultQuality) {
          const ArrDefaultQuality = qualities.value.filter((quality: Quality) => {
            return quality.id === Number(defaultQuality)
          })
          if (ArrDefaultQuality.length) {
            // eslint-disable-next-line prefer-destructuring
            packingResultDetail.quality = ArrDefaultQuality[0]
          }
        }
      }
    }

    const getVarietyByItem = async () => {
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('packing_result.msg.load_data_fail') as string)
      }
    }

    const getData = async (): Promise<void> => {
      loading.value = true
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(endpoints.COLORS),
          api.get(endpoints.BOX_TYPE),
          api.get(endpoints.UNITS),
          api.get(endpoints.CUSTOMERS),
          api.get(endpoints.ORDER_TYPES),
          api.get(endpoints.NURSERY_COMPANY),
          api.get(endpoints.CUSTOMER_GROUP),
          api.get(`${endpoints.COMMON}get_setting`)
        ])

        const [
          { data: qualitiesByQualityGroupData },
          { data: sizesBySizeGroupData },
          { data: colorData },
          { data: boxTypesData },
          { data: unitData },
          { data: customersData },
          { data: orderTypesData },
          { data: nurseryCompaniesData },
          { data: customerGroupsData },
          { data: homeData }
        ] = data

        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)
        colors.value = toCamelCase(colorData)
        boxTypes.value = toCamelCase(boxTypesData)
        units.value = toCamelCase(unitData)
        customers.value = toCamelCase(customersData)
        nurseryCompanies.value = toCamelCase(nurseryCompaniesData)
        orderTypes.value = toCamelCase(orderTypesData)
        listStateSetting.value = toCamelCase(homeData).setting.states?.packing
          ? toCamelCase(homeData).setting.states.packing
          : []
        customerGroups.value = toCamelCase(customerGroupsData)

        const match: any = matchData(
          varietiesByItem.value,
          sizesBySizeGroup.value,
          qualitiesByQualityGroup.value
        )

        items.value = match.items
        varieties.value = match.varieties
        sizes.value = match.sizes
        sizeGroups.value = match.sizeGroups
        qualities.value = match.qualities
        qualityGroups.value = match.qualityGroups

        await getDetailInfo()

        if ($store.state.listStates.packing.length === 0) {
          listStateStore.value.packing = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          defaultObject.listPackingStates,
          $store.state.listStates.packing,
          listStateSetting.value
        )
      } catch (e) {
        showError(e, $toast, root.$t('packing_result.msg.load_data_fail') as string)
      } finally {
        loading.value = false
      }
    }

    watch(
      () => $store.state.listStates.packing,
      () => {
        if ($store.state.listStates.packing.length === 0) {
          listStateStore.value.packing = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          defaultObject.listPackingStates,
          $store.state.listStates.packing,
          listStateSetting.value
        )
      }
    )

    const generateBody = () => {
      const breakdowns = packingResultDetail.breakdowns
        .filter((breakdownDetail: IBreakdown) => {
          return (
            breakdownDetail.item ||
            breakdownDetail.variety ||
            breakdownDetail.size ||
            breakdownDetail.quality ||
            breakdownDetail.color ||
            breakdownDetail.quantity
          )
        })
        .map((breakdownDetail: IBreakdown) => {
          return {
            item_id: breakdownDetail.item?.id,
            variety_id: breakdownDetail.variety?.id,
            size_id: breakdownDetail.size?.id,
            quality_id: breakdownDetail.quality?.id,
            color_id: breakdownDetail.color?.id,
            quantity: breakdownDetail.quantity
          }
        })

      let orderedBoxes = 0
      let orderedStems = 0
      if (orderDetailsData.value.length) {
        orderDetailsData.value.forEach((orderDetailData: PackingOrderDetail) => {
          orderedBoxes += orderDetailData.boxes
          orderedStems += orderDetailData.stems
        })
      }
      const lengthPackingImportDetails = packingResultDetail.packingImportDetails?.length || null
      let packingImportDetailId = null
      if (lengthPackingImportDetails) {
        packingImportDetailId =
          lengthPackingImportDetails === 1 ? packingResultDetail.packingImportDetails?.[0].id : null
      }

      return {
        packedDate: packingResultDetail.packedDate,
        quantity: packingResultDetail.quantity,
        boxes: packingResultDetail.boxes,
        totalStems: packingResultDetail.totalStems,
        desiredPrice: packingResultDetail.desiredPrice,
        itemId: packingResultDetail.item?.id,
        sizeId: packingResultDetail.size?.id,
        colorId: packingResultDetail.color?.id,
        qualityId: packingResultDetail.quality?.id,
        boxTypeId: packingResultDetail.boxType?.id,
        unitId: packingResultDetail.unit?.id,
        varietyId: packingResultDetail.variety?.id,
        remark: packingResultDetail.remark?.trim(),
        breakdowns,
        orderedBoxes,
        orderedStems,
        assignedBoxes: packingResultDetail.assignedBoxes ?? 0,
        assignedStems: packingResultDetail.assignedStems ?? 0,
        packingImportId: packingImportDetailId
      }
    }

    const createDetail = async (): Promise<PackingResult | null> => {
      uploadLoading.value = true
      try {
        const requestBody = toSnakeCase(generateBody())
        const { data } = await api.post(endpoints.PACKING_RESULTS, requestBody)
        $toast.success(root.$t('common.msg.create_success'))
        return toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        await setTimeout(() => {
          uploadLoading.value = false
        }, 200)
      }
      return null
    }

    const updateDetail = async (): Promise<PackingResult | null> => {
      uploadLoading.value = true
      try {
        const requestBody = toSnakeCase(generateBody())
        const { data } = await api.put(`${endpoints.PACKING_RESULTS}${detailId}`, requestBody)
        $toast.success(root.$t('common.msg.update_success'))
        return toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        await setTimeout(() => {
          uploadLoading.value = false
        }, 200)
      }
      return null
    }

    const validatePackingResult = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const valid = await form.validate()

      return valid
    }

    const createOrUpdateAssignment = async (detail: PackingResult): Promise<void> => {
      // assign pk
      const batchCreateData = [] as Array<IAssign>
      const createIndex = [] as Array<number>
      const batchUpdateData = [] as Array<IAssign>

      packingResultDetail.deliveries.map(async (delivery: IDelivery, index: number) => {
        const body: IAssign = {
          boxes: delivery.boxes,
          stems: delivery.stems,
          price: delivery.price,
          amount: parseInt(delivery.price, 10) * parseInt(delivery.stems, 10),
          orderDetailId: delivery.orderDetailId,
          buyerInfo: delivery.buyerInfo,
          bundleNo: delivery.bundleNo,
          customerId: delivery.customer.id,
          orderTypeId: delivery.orderType.id,
          auctionDate: delivery.auctionDate,
          packingResultId: detail.id,
          quantity: detail.quantity,
          itemId: detail.item.id,
          varietyId: detail.variety.id,
          sizeId: detail.size.id,
          qualityId: detail.quality.id,
          unitId: detail.unit.id,
          boxTypeId: detail.boxType.id,
          remark: detail.remark?.trim(),
          breakdowns: detail.breakdowns,
          colorsId: detail.color ? [detail.color.id] : null
        }
        if (delivery.id) {
          body.id = delivery.id
          batchUpdateData.push(toSnakeCase(body))
        } else {
          batchCreateData.push(toSnakeCase(body))
          createIndex.push(index)
        }
      })

      // update assignment
      if (batchUpdateData.length) {
        await api.put(`${endpoints.ASSIGNMENTS}batch_update`, batchUpdateData)
      }

      // create assignment
      if (batchCreateData.length) {
        const { data } = await api.post(`${endpoints.ASSIGNMENTS}batch_create`, batchCreateData)
        data.forEach((row: any, index: number) => {
          packingResultDetail.deliveries[createIndex[index]].id = row.id
        })
      }

      // delete assignment
      if (removedDeliveryIds.length) {
        const ids = removedDeliveryIds.join(',')
        await api.delete(`${endpoints.ASSIGNMENTS}batch_delete/${ids}`)
        removedDeliveryIds = []
      }
    }

    const createOrUpdateDetail = async (enableGoBack = false): Promise<PackingResult | null> => {
      let newDetail = null
      if (id.value) {
        newDetail = await updateDetail()
      } else {
        newDetail = await createDetail()
      }

      if (newDetail) {
        await createOrUpdateAssignment(newDetail)
        framebus.emit(frameBusEvent.UPDATE_CALENDAR, { isUpdate: true })
      }

      if (enableGoBack && newDetail) {
        backPage()
      }

      if (newDetail && !id.value) {
        detailId = String(newDetail.id)
        id.value = newDetail.id

        $router
          .replace({
            name: urlPath.PACKING_RESULT_DETAIL.name,
            params: { detailId },
            query: { called: 'true' }
          })
          .catch((e) => {
            console.log(e)
          })
      }
      if (isOnPC) {
        setTimeout(() => {
          onSaving.value = false
        }, 300)
      } else {
        onSaving.value = false
      }
      await getDetailInfo()
      return newDetail
    }

    const checkBeforeCreateOrUpdate = async (
      enableGoBack = false
    ): Promise<PackingResult | null> => {
      if (onSaving.value) {
        return null
      }
      onSaving.value = true
      const valid = await validatePackingResult()
      if (!valid) {
        onSaving.value = false
        return null
      }

      const deliveryBoxes = packingResultDetail.deliveries.reduce(
        (prev: number, cur: IDelivery) => prev + cur.boxes,
        0
      )
      const deliveryStems = packingResultDetail.deliveries.reduce(
        (prev: number, cur: IDelivery) => prev + cur.stems,
        0
      )
      if (
        deliveryBoxes > packingResultDetail.boxes ||
        deliveryStems > packingResultDetail.totalStems
      ) {
        showConfirmOverBoxesDialog.value = true
        onSaving.value = false
        return null
      }

      const result = await createOrUpdateDetail(enableGoBack)
      onSaving.value = false
      return result
    }

    const close = () => {
      backPage()
    }

    const deleteDetail = async (): Promise<boolean> => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.PACKING_RESULTS}${detailId}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        deleteLoading.value = false
      }
      return false
    }

    const deletePackingDetail = () => {
      // remove keydown event to prevent overwrite delete confirm dialog event
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener('keydown', onTyping)

      showDelete.value = true
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteDetail()
      }
      if (success) {
        backPage()
      }
      showDelete.value = false

      // Add keydown event
      // eslint-disable-next-line no-use-before-define
      document.addEventListener('keydown', onTyping)
    }

    const getPropertyDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}.title`) as string
    }

    const getNumberDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}`) as string
    }

    const addBreakdown = () => {
      if (!componentKeyBreakdown.value) {
        // avoid open when edit
        componentKeyBreakdown.value += 1
      } else {
        componentKeyBreakdown.value += packingResultDetail.breakdowns.length
      }
      packingResultDetail.breakdowns = [].concat(
        packingResultDetail.breakdowns,
        reactive({ ...initBreakdown })
      )
    }

    const updateBreakdown = (data: IBreakdown, index: number) => {
      if (index > -1) {
        packingResultDetail.breakdowns[index] = data
      }
    }

    const removeBreakdown = (index: number) => {
      if (index > -1) {
        packingResultDetail.breakdowns.splice(index, 1)
      }
    }

    const createNewDetail = async (): Promise<void> => {
      $router
        .replace({
          name: urlPath.PACKING_RESULT_DETAIL.name,
          params: {
            detailId: '0'
          },
          query: {
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })

      // clear data
      detailId = '0'
      id.value = null
      packingResultDetail.item = null
      packingResultDetail.variety = null
      packingResultDetail.quality = null
      packingResultDetail.size = null
      packingResultDetail.color = null
      packingResultDetail.boxType = null
      packingResultDetail.unit = null
      packingResultDetail.quantity = null
      packingResultDetail.boxes = null
      packingResultDetail.totalStems = null
      packingResultDetail.desiredPrice = null
      packingResultDetail.breakdowns = []
      packingResultDetail.deliveries = []
      packingResultDetail.remark = null
      scrollTop()
    }

    const saveCurrentStates = async () => {
      try {
        const setting = { data: $store.state.listStates.packing, key: 'packing' }
        await api.put(`${endpoints.COMMON}update_setting_list_states_default`, setting)
        $toast.success(root.$t('common.msg.save_input_state_successful'))
        isShowAction.value = false
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      }
    }

    const duplicateDetail = (): void => {
      $router
        .replace({
          name: urlPath.PACKING_RESULT_DETAIL.name,
          params: {
            detailId: '0'
          },
          query: {
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })

      // clear boxes and stems
      detailId = '0'
      id.value = null
      packingResultDetail.boxes = null
      packingResultDetail.totalStems = null
      packingResultDetail.breakdowns = []
      packingResultDetail.deliveries = []
      packingResultDetail.assignedBoxes = 0
      packingResultDetail.assignedStems = 0
      packingResultDetail.orderedStems = 0
      packingResultDetail.orderedBoxes = 0
      scrollTop()
    }

    const totalBoxesDelivery = computed(() => {
      let count = 0
      if (packingResultDetail.deliveries && packingResultDetail.deliveries.length > 0) {
        packingResultDetail.deliveries.forEach((delivery: IDelivery) => {
          count += delivery.boxes
        })
      }
      return count
    })
    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        checkBeforeCreateOrUpdate(false)
      }

      if (id.value) {
        if (event.keyCode === 67) {
          // Press C
          duplicateDetail()
        } else if (event.keyCode === 78) {
          // Press N
          createNewDetail()
        } else if (event.keyCode === 27) {
          // Press Esc
          close()
        } else if (event.keyCode === 68) {
          // Press D
          deletePackingDetail()
        }
      }

      if (!event.shiftKey && !event.ctrlKey && event.keyCode >= 48 && event.keyCode <= 57) {
        const field = mappingKeyAndField.find(
          (e: MappingKeyAndField) => e.keyCode === event.keyCode
        )
        if (field) {
          chooseProperty(field.field)
        }
      }
    }

    watch(
      () => [dialog.value, numberDialog.value, modal.value, colorDialog.value],
      ([showDialog, showNumberDialog, showDateDialog, showColorDialog]) => {
        if (showDialog || showNumberDialog || showDateDialog || showColorDialog) {
          document.removeEventListener('keydown', onTyping)
        } else {
          document.addEventListener('keydown', onTyping)
        }
      }
    )

    const addEventTyping = (): void => {
      document.addEventListener('keydown', onTyping)
    }

    const removeEventTyping = (): void => {
      document.removeEventListener('keydown', onTyping)
    }

    onMounted(async () => {
      document.addEventListener('keydown', onTyping)
      getData().then(() => {
        if (isDuplicated === '1') {
          isDuplicated = '0'
          duplicateDetail()
        }
      })
    })
    scrollTop()

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData: any = $store.state.common.newDataMaster
      // itemSelectVariety.value = 1
      switch (mode) {
        case 'customer':
          listID = customers.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            customers.value.push({ ...newData })
          }
          break
        case 'item':
          items.value.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
          // eslint-disable-next-line no-restricted-globals
          // parent.postMessage(varietiesByItem.value, '*')
          framebus.emit(frameBusEvent.ITEM_VARIETY, {
            contents: varietiesByItem.value
          })
          itemSelectVariety.value = $store.state.common.newDataMaster.id
          break
        case 'variety':
          listID = varieties.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.value.push({ ...newData })
            varietiesByItem.value.map((varietyByItem: VarietiesByItem, index: number) => {
              if (varietyByItem.id === newData.item.id) {
                varietiesByItem.value[index].varieties.push(newData)
              }
              return null
            })
            $store.commit('setAllVarietyByItem', varietiesByItem.value)
            groupDataMaster.value = varietiesByItem.value
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItem.value
            })
          }
          break
        case 'size':
          listID = sizes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.value.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'box_type':
          listID = boxTypes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            boxTypes.value.push({ ...newData })
          }
          break
        case 'quality':
          listID = qualities.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, index: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[index].qualities.push({ ...newData })
              }
              return null
            })
            qualities.value.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        case 'unit':
          listID = units.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            units.value.push({ ...newData })
          }
          break
        default:
      }
      chooseProperty(setPropertyName.value)
      updatePackingResultDetail('next', newData)
    }

    onUnmounted(() => {
      document.removeEventListener('keydown', onTyping)
    })

    return {
      id,
      requiredRows,
      packingResultDetail,
      showConfirmOverBoxesDialog,
      modal,
      numberDialog,
      colorDialog,
      colors,
      dialog,
      showDelete,
      confirmDelete,
      setPropertyName,
      chooseProperty,
      choosePropertyItems,
      updatePackingResultDetail,
      selectProperty,
      createOrUpdateDetail,
      checkBeforeCreateOrUpdate,
      getPropertyDialogTitle,
      getNumberDialogTitle,
      uploadLoading,
      loading,
      deleteLoading,
      addBreakdown,
      updateBreakdown,
      removeBreakdown,
      addDelivery,
      updateDelivery,
      removeDelivery,
      items,
      varieties,
      sizes,
      qualities,
      customers,
      orderTypes,
      customerGroups,
      createNewDetail,
      duplicateDetail,
      close,
      totalBoxesDelivery,
      componentKeyDelivery,
      componentKeyBreakdown,
      thisPage,
      saveCurrentStates,
      isShowAction,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      getNewData,
      itemSelectVariety,
      groupDataMaster,
      getData,
      disabledRows,
      isUpdateCommon,
      deletePackingDetail,
      isOnPC,
      addEventTyping,
      removeEventTyping
    }
  }
})

export default PackingResultDetail
